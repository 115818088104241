import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// 登录
const Login = () => import("../views/login/login");
// 首页
const Home = () => import("../views/index/home");
// 消息
const Message = () => import("../views/index/message");
// 联系人
const Person = () => import("../views/index/person");
// 工作台
const Workbench = () => import("../views/index/workbench");
// 工作报告
const Report = () => import("../views/workbench/report");
// 智能报表
const Statement = () => import("../views/workbench/statement");
//  审批
const Approval = () => import("../views/workbench/approval");

// 待办
const Task = () => import("../views/index/task");

// 客户库
const Warehouse = () => import("../views/index/warehouse");

const routes = [
  {
    path: "/",
    redirect: {
      name: "Login",
    },
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "登录",
      needLogin: false, // 是否需要登录
    },
    component: Login,
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      title: "厦门安惠信信息科技有限公司",
      needLogin: true, // 是否需要登录
    },
    component: Home,
    redirect: {
      name: "Message",
    },
    children: [
      {
        path: "message",
        name: "Message",
        component: Message,
        meta: {
          title: "厦门安惠信信息科技有限公司",
          needLogin: true, // 是否需要登录
        },
      },
      {
        path: "person",
        name: "Person",
        meta: {
          title: "厦门安惠信信息科技有限公司",
          needLogin: true, // 是否需要登录
        },
        component: Person,
      },
      {
        path: "workbench",
        name: "Workbench",
        meta: {
          title: "厦门安惠信信息科技有限公司",
          needLogin: true, // 是否需要登录
        },
        component: Workbench,
        redirect: {
          name: "Report",
        },
        children: [
          {
            path: "report",
            name: "Report",
            meta: {
              title: "厦门安惠信信息科技有限公司",
              needLogin: true, // 是否需要登录
            },
            component: Report,
          },
          {
            path: "statement",
            name: "Statement",
            meta: {
              title: "厦门安惠信信息科技有限公司",
              needLogin: true, // 是否需要登录
            },
            component: Statement,
          },
          {
            path: "approval",
            name: "Approval",
            meta: {
              title: "厦门安惠信信息科技有限公司",
              needLogin: true, // 是否需要登录
            },
            component: Approval,
          },
        ],
      },
      {
        path: "warehouse",
        name: "Warehouse",
        component: Warehouse,
        meta: {
          title: "厦门安惠信信息科技有限公司",
          needLogin: true, // 是否需要登录
        },
      },
      {
        path: "task",
        name: "Task",
        meta: {
          title: "厦门安惠信信息科技有限公司",
          needLogin: true, // 是否需要登录
        },
        component: Task,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.path === from.path) {
//     next(false);
//   } else {
//     next();
//   }
// });

export default router;
